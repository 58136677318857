<template>
  <div class="py-bw-5">
    <b-container fluid class="py-bw-5 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <h1 class="display-bw-3 mb-4">
              {{ $t("inclusive.header.title") }}
            </h1>
            <p class="p-bw mb-5 px-5">
              {{ $t("inclusive.header.content") }}
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-row class="py-bw-5">
        <b-col class="px-0">
          <swiper ref="trainersSwiper"
            :options="swiperOptionTrainers"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
          >
            <swiper-slide :key="trainer.id" v-for="trainer in trainers">
              <div class="card-trainer">
                <img
                  :src="require(`@/assets/imgs/trainers/${trainer.imgSrc}`)"
                  :alt="trainer.name"
                >
              </div>
            </swiper-slide>
          </swiper>
          <!-- <b-button
            variant="dark"
            class="w-25 mt-5 mr-5"
            :to="{ name: 'About', hash: '#trainers'}"
          >
            Become a trainer
          </b-button>
          <b-button
            variant="outline-primary"
            class="w-25 mt-5"
            :to="{ name: 'About', hash: '#trainers'}"
            disabled
          >
            Meet our team
          </b-button> -->
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pb-8 text-left section-promise">
      <b-row>
        <b-col cols="12" md="6">
          <div class="img-inclusive-group-a" alt="Barliner Workout"></div>
        </b-col>
        <b-col cols="12" md="6" class="px-5 my-auto mt-sm-6">
          <div class="pl-5">
            <p class="p-bw-bold mb-2">
              {{ $t('inclusive.section1.subtitle') }}
            </p>
            <h2 class="h2-bw mb-4">
              {{ $t('inclusive.section1.title') }}
            </h2>
            <p class="p-bw">
              {{ $t('inclusive.section1.content') }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="bg-accent py-bw-5 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <p class="p-bw-bold mb-2">
              {{ $t("inclusive.section2.subtitle") }}
            </p>
            <h2 class="h2-bw mb-4">
              {{ $t("inclusive.section2.title") }}
            </h2>
             <p class="p-bw">
              {{ $t('inclusive.section2.content') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-row class="py-bw-5">
        <b-col class="px-0">
          <div style="height: 350px;"></div>
        </b-col>
      </b-row>
    </b-container>
    <contact></contact>
  </div>
</template>

<script>
export default {
  name: 'Inclusive',
  components: {
    Contact: () => import('@/components/Contact')
  },
  data () {
    return {
      trainers: [
        {
          id: 1,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 2,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 3,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        },
        {
          id: 4,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 5,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 6,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 7,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 8,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 9,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 10,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        }
      ],
      swiperOptionTrainers: {
        slidesPerView: 5,
        spaceBetween: 0,
        freeMode: true,
        grabCursor: true,
        loop: true,
        initialSlide: 0,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true
        },
        speed: 800,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-header {
    .card-trainer {
      height: 480px;
      margin-right: .75rem;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .section-promise {
    .img-inclusive-group-a {
      @extend .img-background;
      height: 100%;
      min-height: 492px;
      background-image: url('../assets/imgs/landing_group_a_3x.jpg');
      background-size: cover;
      background-position: center;
    }
  }
</style>
